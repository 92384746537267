import { a as __assign } from '../common/tslib.es6-608d9385.js';
import { O as Observable } from '../common/Observable-f55a3148.js';
import { m as map } from '../common/map-f652d571.js';

/**
 * @license
 * Copyright 2018 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
function _fromRef(ref, options) {
    /* eslint-enable @typescript-eslint/no-explicit-any */
    return new Observable(function (subscriber) {
        var unsubscribe = ref.onSnapshot(options || {}, subscriber);
        return { unsubscribe: unsubscribe };
    });
}
function fromRef(ref, options) {
    return _fromRef(ref, options);
}
function fromDocRef(ref, options) {
    return fromRef(ref, options);
}
function fromCollectionRef(ref, options) {
    return fromRef(ref, options);
}

/**
 * @license
 * Copyright 2018 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function doc(ref) {
    return fromDocRef(ref);
}
/**
 * Returns a stream of a document, mapped to its data payload and optionally the document ID
 * @param query
 */
function docData(ref, idField) {
    return doc(ref).pipe(map(function (snap) { return snapToData(snap, idField); }));
}
function snapToData(snapshot, idField) {
    var _a;
    return __assign(__assign({}, snapshot.data()), (idField ? (_a = {}, _a[idField] = snapshot.id, _a) : null));
}
/**
 * Return a stream of document snapshots on a query. These results are in sort order.
 * @param query
 */
function collection(query) {
    return fromCollectionRef(query).pipe(map(function (changes) { return changes.docs; }));
}
/**
 * Returns a stream of documents mapped to their data payload, and optionally the document ID
 * @param query
 */
function collectionData(query, idField) {
    return collection(query).pipe(map(function (arr) {
        return arr.map(function (snap) { return snapToData(snap, idField); });
    }));
}

export { collection, collectionData, docData };
