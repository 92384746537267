import { O as Observable } from '../common/Observable-f55a3148.js';
import { f as from } from '../common/from-f09944cd.js';
import { m as map } from '../common/map-f652d571.js';
import '../common/tslib.es6-608d9385.js';
import '../common/scheduleArray-19691874.js';

/**
 * @license
 * Copyright 2018 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
function fromTask(task) {
    return new Observable(function (subscriber) {
        var progress = function (snap) { return subscriber.next(snap); };
        var error = function (e) { return subscriber.error(e); };
        var complete = function () { return subscriber.complete(); };
        task.on('state_changed', progress, error, complete);
        return function () { return task.cancel(); };
    });
}
function getDownloadURL(ref) {
    return from(ref.getDownloadURL());
}
function put(ref, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
data, metadata) {
    return fromTask(ref.put(data, metadata));
}
function percentage(task) {
    return fromTask(task).pipe(map(function (s) { return ({
        progress: (s.bytesTransferred / s.totalBytes) * 100,
        snapshot: s
    }); }));
}

export { fromTask, getDownloadURL, percentage, put };
