function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { LitElement, html, property, customElement } from '../_snowpack/pkg/lit-element.js';
import { outlet } from "../_snowpack/pkg/eagrouter.js";
import { BehaviorSubject, of } from '../_snowpack/pkg/rxjs.js';
import { fireAuthState } from './server/firebase.config.js';
import { map } from '../_snowpack/pkg/rxjs/operators.js';
export class AppRoot extends LitElement {
  constructor(...args) {
    super(...args);

    _defineProperty(this, "routes", [{
      path: "/",
      redirect: "/home"
    }, //   For pages without lazyloading, use like this
    {
      component: "<home-page></home-page>",
      path: "/home",
      bundle: () => import("./client/home.js") // guard: () => this.returnFalse()

    }, {
      component: "<product-page></product-page>",
      path: "/product",
      bundle: () => import("./client/product.js") // guard: () => this.returnFalse()

    }, {
      component: "<admin-area></admin-area>",
      path: "/dashboard",
      bundle: () => import("./admin/dashboard.js")
    }, {
      component: "<view-product></view-product>",
      path: "/products",
      bundle: () => import("./admin/products/products.js")
    }, {
      component: "<add-product></add-product>",
      path: "/add-products",
      bundle: () => import("./admin/products/add.js")
    }, {
      component: "<edit-product></edit-product>",
      path: "/edit-products",
      bundle: () => import("./admin/products/edit.js")
    }, {
      component: "<sales-page></sales-page>",
      path: "/sales",
      bundle: () => import("./client/sales.js")
    }, {
      component: "<new-page></new-page>",
      path: "/new",
      bundle: () => import("./client/new.js")
    }, {
      component: "<product-variant></product-variant>",
      path: "/product-variant",
      bundle: () => import("./admin/products/variant.js")
    }, {
      component: "<view-users></view-users>",
      path: "/users",
      bundle: () => import("./admin/users/users.js")
    }, {
      component: "<view-admins></view-admins>",
      path: "/view-admins",
      bundle: () => import("./admin/users/admin.js")
    }, {
      component: "<view-discounts></view-discounts>",
      path: "/discounts",
      bundle: () => import("./admin/discount/index.js")
    }, {
      component: "<add-discount></add-discount>",
      path: "/add-discount",
      bundle: () => import("./admin/discount/add.js")
    }, {
      component: "<edit-discount></edit-discount>",
      path: "/edit-discount",
      bundle: () => import("./admin/discount/edit.js")
    }, {
      component: "<view-category></view-category>",
      path: "/categories",
      bundle: () => import("./admin/products/category.js")
    }, {
      component: "<add-category></add-category>",
      path: "/add-category",
      bundle: () => import("./admin/products/add-category.js")
    }, {
      component: "<edit-category></edit-category>",
      path: "/edit-category",
      bundle: () => import("./admin/products/edit-category.js")
    }, {
      component: "<image-manager></image-manager>",
      path: "/images",
      bundle: () => import("./admin/images/index.js")
    }, {
      component: "<view-orders></view-orders>",
      path: "/orders",
      bundle: () => import("./admin/orders/index.js")
    }, {
      component: "<news-letter></news-letter>",
      path: "/newsletters",
      bundle: () => import("./admin/newsletter/index.js")
    }, {
      component: "<single-product></single-product>",
      path: "/single",
      bundle: () => import("./client/single.js") // guard: () => this.returnFalse()

    }, {
      component: "<my-profile></my-profile>",
      path: "/my-profile",
      bundle: () => import("./client/profile.js") // guard: () => this.returnFalse()

    }, //Login and registration
    {
      component: "<login-page></login-page>",
      path: "/login",
      bundle: () => import("./auth/login.js")
    }, {
      component: "<about-us></about-us>",
      path: "/about",
      bundle: () => import("./client/about.js")
    }, {
      component: "<contact-us></contact-us>",
      path: "/contact",
      bundle: () => import("./client/contact.js")
    }, {
      component: "<checkout-area></checkout-area>",
      path: "/checkout",
      bundle: () => import("./client/checkout.js")
    }]);

    _defineProperty(this, "sobsSubje", new BehaviorSubject(false));
  }

  createRenderRoot() {
    return this;
  }

  returnFalse() {
    return fireAuthState.pipe(map(user => {
      if (user && user.emailVerified) {
        // console.log(user)
        return true;
      }

      outlet('home');
      return false;
    }));
  }

  connectedCallback() {
    super.connectedCallback(); // this.sobsSubje.subscribe(item => { alert(item)})
  }

  render() {
    return html`
      
      <eag-router .routes=${this.routes}></eag-router>
      `;
  }

}
customElements.define("app-root", AppRoot);