import firebase from '../../_snowpack/pkg/firebase/app.js';
import '../../_snowpack/pkg/firebase/firestore.js';
import '../../_snowpack/pkg/firebase/storage.js';
import '../../_snowpack/pkg/firebase/auth.js';
import { authState } from '../../_snowpack/pkg/rxfire/auth.js';
import { collection, collectionData, docData } from '../../_snowpack/pkg/rxfire/firestore.js';
import '../../_snowpack/pkg/firebase/functions.js';
import { refCount, shareReplay, publishReplay, switchMap, filter, map } from '../../_snowpack/pkg/rxjs/operators.js';
import { BehaviorSubject, of } from '../../_snowpack/pkg/rxjs.js';
import { getDownloadURL } from '../../_snowpack/pkg/rxfire/storage.js';
const firebaseConfig = {
  apiKey: 'AIzaSyAz0b9_itn4O8JR38FVmayb80nxMmu34wk',
  authDomain: 'xdisc-cd76a.firebaseapp.com',
  projectId: 'xdisc-cd76a',
  storageBucket: 'xdisc-cd76a.appspot.com',
  messagingSenderId: '370897691864',
  appId: '1:370897691864:web:cdd13bbf31af4c60e0a3ad'
};
firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const emailPasswordProvider = firebase.auth.EmailAuthProvider;
export const firebaseFunctions = firebase.functions();
export const fireAuthState = authState(auth);
export const userOrders$ = fireAuthState.pipe(switchMap(user => user?.uid ? getUserOrders(user.uid) : of([]))); //Register Users

export const registerEmailandPassword = (email, password) => {
  return auth.createUserWithEmailAndPassword(email, password);
};
export const updateDetailsafterSignup = (userId, cname, cnip, phone, email) => {
  return db.doc(`users/${userId}`).set({
    userId,
    cname,
    cnip,
    phone,
    email
  }, {
    merge: true
  });
}; // language

const langSubject$ = new BehaviorSubject('PL');
export const language$ = langSubject$.pipe(switchMap(_ => {
  const language = localStorage.getItem('language');

  if (language) {
    return of(language);
  }

  return of('PL');
}));
export const changeLanguage = lan => {
  localStorage.setItem('language', lan);
  langSubject$.next(lan);
}; //get user profile

export const getallUserProfile = () => {
  const ref = db.collection('users');
  return collectionData(ref, 'id');
};
export const getUserProfile = id => {
  const ref = db.doc(`users/${id}`);
  return docData(ref, 'id');
};
export const editUserProfile = (id, valueFields) => {
  return db.doc(`users/${id}`).set(valueFields, {
    merge: true
  });
}; //Login Users

export const signInUsers = (email, password) => {
  return auth.signInWithEmailAndPassword(email, password);
};
export const userlogout = () => {
  auth.signOut();
}; // get categories from database

export const getCategoriesFromFirebase = () => {
  const ref = db.collection('categories');
  return collectionData(ref, 'id').pipe(shareReplay(1));
}; //get product from databse

export const getProductFromFirebase = () => {
  const ref = db.collection('products');
  return collectionData(ref, 'id');
};
export const createProduct = product => {
  return db.collection('products').add(product); // return collectionData(ref, "id");
};
export const editProduct = (id, valueFields) => {
  console.log(valueFields);
  return db.doc(`products/${id}`).set(valueFields, {
    merge: true
  });
};
export const getOneProductFromFirebase = id => {
  const ref = db.doc(`products/${id}`);
  return docData(ref, 'id');
};
export const deleteProduct = id => {
  return db.doc(`products/${id}`).delete();
}; //get all product by category

export const getProductFromFirebaseByCategory = category => {
  const ref = db.collection('products').orderBy('productSku', 'asc').where('productCategory', '==', category);
  return collectionData(ref, 'id');
}; //get all featured

export const getProductFromFirebasebyFeatured = () => {
  const ref = db.collection('products').where('productFeatured', '==', 'yes');
  return collectionData(ref, 'id');
};
export const getProductFromFirebasebyPromotion = () => {
  const ref = db.collection('products').where('productPromotion', '==', 'Yes');
  return collectionData(ref, 'id');
};
export const getProductFromFirebasebyNew = () => {
  const ref = db.collection('products').where('newProduct', '==', 'Yes');
  return collectionData(ref, 'id');
}; // Categories

export const createCategories = categories => {
  return db.collection('categories').add(categories);
};
export const getCategories = () => {
  const ref = db.collection('categories');
  return collectionData(ref, 'id');
};
export const getOneCategories = id => {
  const ref = db.doc(`categories/${id}`);
  return collectionData(ref, 'id');
};
export const editCategory = (id, valueFields) => {
  return db.doc(`categories/${id}`).set(valueFields, {
    merge: true
  });
};
export const deleteCategory = id => {
  return db.doc(`categories/${id}`).delete();
}; //images

export const saveImage = images => {
  return db.collection('images').add(images);
};
export const getimageFromFirebase = () => {
  const ref = db.collection('images');
  return collectionData(ref, 'id');
};
export const deleteImage = async (id, path) => {
  await db.doc(`images/${id}`).delete();
  return await storage.ref(`${path}`).delete();
}; //orders

export const createOrders = orders => {
  return db.collection('orders').add(orders);
};
export const getOneOrders = id => {
  const ref = db.doc(`orders/${id}`);
  return collectionData(ref, 'id');
};
export const getOrders = () => {
  const ref = db.collection('orders').orderBy('date', 'desc');
  return collectionData(ref, 'id');
};
export const editOrders = (id, valueFields) => {
  return db.doc(`orders/${id}`).set(valueFields, {
    merge: true
  });
};

const getUserOrders = userId => {
  const ref = db.collection(`orders`).where('userId', '==', userId).limit(10);
  return collectionData(ref, 'id');
}; //Discounts


export const createDiscount = discounts => {
  return db.collection('discounts').add(discounts);
};
export const getDiscount = () => {
  const ref = db.collection('discounts');
  return collectionData(ref, 'id');
};
export const editDiscount = (id, valueFields) => {
  return db.doc(`discounts/${id}`).set(valueFields, {
    merge: true
  });
};
export const deleteDiscount = id => {
  return db.doc(`discounts/${id}`).delete();
}; // Cart

export const userCart$ = fireAuthState.pipe(filter(user => user !== null), switchMap(user => getUserCart(user.uid)), shareReplay(1));
export const getUserCart = id => {
  const ref = db.doc(`cart/${id}`);
  return docData(ref);
};
export const deletecart = async user => {
  try {
    db.doc(`cart/${user}`).delete();
  } catch (error) {
    console.log(error);
  }
};
export const addToCartFirebase = (id, products) => {
  return db.doc(`cart/${id}`).set({
    products
  }, {
    merge: true
  });
}; //NewsLetter

export const addEmail = email => {
  return db.collection('email').add(email);
};
export const getEmails = () => {
  const ref = db.collection('email');
  return collectionData(ref, 'id');
};
export const deleteEmail = id => {
  return db.doc(`email/${id}`).delete();
}; //Banners

export const getHomeBanner = () => {
  const ref = db.doc('banner/8Hyenq46bsqHe6VUkBU5');
  return docData(ref, 'id');
}; // Ratings

export const addRating = rating => {
  return db.collection('rating').add(rating);
};
export const getRating = () => {
  const ref = db.collection('rating');
  return collectionData(ref, 'id');
}; // Contact us

export const addMessage = contactForm => {
  return db.collection('contactForm').add(contactForm);
};
export const getMessage = () => {
  const ref = db.collection('contactForm');
  return collectionData(ref, 'id');
};
export const sendOrderConfirmation = (email, userid) => {
  return fnc.httpsCallable('sendOrderConfirmation')({
    email,
    userid
  });
};
export const sendOrderConfirmationAdmin = (email, userid) => {
  return fnc.httpsCallable('sendOrderConfirmationAdmin')({
    email,
    userid
  });
};